<template>
  <div class="facilityBox" v-loading="flag.dialogLoading">
    <!-- 主体区域 -->
    <div class="bodyArea" v-show="!flag.showHint">
      <div class="title">运单信息进度</div>
      <!-- 步骤条 -->
      <Step class="step" :activeList="[1, 1, 1, 1]"></Step>
      <!-- 温馨提示 -->
      <el-alert
        style="margin-bottom: 10px"
        title="温馨提示"
        type="warning"
        description="1.如服务商上传的发票有错误，点击“驳回”按钮，把运单返回给服务商修改，重新提交后，您会再次收到运单。2.点击“确认收票”视为您同意服务商上传的发票，本运单将结束。3.点击“下载电子发票”，将把运单中所有的电子发票给您"
        :closable="false"
      >
      </el-alert>
      <!-- 合计 -->
      <div class="settleBox">
        <el-row>
          <el-col :span="5"
            >开票申请时间：<strong>{{
              settlementInfo.JsDateTime
            }}</strong></el-col
          >
          <el-col :span="5"
            >运单数量(条)：<strong>{{
              settlementInfo.WayBillCount
            }}</strong></el-col
          >
          <el-col :span="5"
            >{{ getWaybillType.sceneId == 7 ? "总工单费(元)：" : "总运费(元)："
            }}<strong>￥{{ settlementInfo.TotalFreight }}</strong></el-col
          >
          <el-col :span="9"
            >服务费/税费(元)：<strong
              >￥{{ settlementInfo.TotalTax }}</strong
            ></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="5"
            >{{
              getWaybillType.sceneId == 7
                ? "工单总金额(元)："
                : "运单总金额(元)："
            }}<strong>￥{{ settlementInfo.TotalAmount }}</strong></el-col
          >
          <el-col :span="5"
            >货主单位：<strong>{{
              consignInfo.InvoiceTitleName
            }}</strong></el-col
          >
          <el-col :span="5"
            >货主税号：<strong>{{
              consignInfo.InvoiceTitleCreditCode
            }}</strong></el-col
          >
          <el-col :span="9"
            >货主地址：<strong>{{
              consignInfo.InvoiceTitleAddress
            }}</strong></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="5"
            >货主电话：<strong>{{
              consignInfo.InvoiceTitlePhone
            }}</strong></el-col
          >
          <el-col :span="5"
            >货主账号：<strong>{{
              consignInfo.InvoiceTitleBankerNumber
            }}</strong></el-col
          >
          <el-col :span="5" v-if="settlementInfo.InvoiceKindTec"
            >专票普票：<strong
              >运费-{{ settlementInfo.InvoiceKind }} 技术服务费-{{
                settlementInfo.InvoiceKindTec
              }}</strong
            ></el-col
          >
          <el-col :span="5" v-else
            >专票普票：<strong>{{ settlementInfo.InvoiceKind }}</strong></el-col
          >
          <el-col :span="9"
            >货主开户行：<strong>{{
              consignInfo.InvoiceTitleBankerName
            }}</strong></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="5"
            >支付方式：<strong>{{ settlementInfo.PayName }}</strong></el-col
          >
        </el-row>
      </div>
      <!-- 表格信息 -->
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
      >
        <el-table-column
          fixed
          align="center"
          prop="index"
          label="序号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceTaskID"
          label="开票申请编号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PrintTaskID"
          label="发票任务单号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceType"
          label="发票类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskType"
          label="业务类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskSceneName"
          label="场景类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ConsignName"
          label="货主单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="SupplierName"
          label="服务商单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="WaybillCount"
          label="包含运单数量"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarriageAmount"
          :label="getWaybillType.sceneId == 7 ? '工单费' : '运费'"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="expen"
          label="服务费/税费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.expen | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayTotalAmount"
          :label="getWaybillType.sceneId == 7 ? '工单总金额' : '运单总金额'"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.PayTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceAmount"
          label="发票面额"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceNumber"
          label="发票号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CreateTime"
          label="开票日期"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="open_detail(scope.row)"
              size="mini"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
      <!-- 右下角按钮 -->
      <div class="funcs">
        <el-button size="mini" @click="goBack">返回</el-button>
        <el-button type="warning" size="mini" @click="openRejectModal"
          >驳回</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="downloadAllInvoice"
          :loading="flag.downloadAllInvoiceing"
          :disabled="flag.disallowance == 1"
          >下载全部电子发票及清单</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="confirmReceipt"
          :loading="flag.confirmReceipting"
          :disabled="
            flag.disallowance == 1 ||
            flag.confirmReceiptedStatus1 ||
            flag.confirmReceiptedStatus2
          "
          >确认收票</el-button
        >
      </div>
    </div>
    <!-- 表格弹窗 -->
    <el-dialog
      :visible.sync="flag.showTableDetail"
      title="发票详情"
      width="80%"
      center
    >
      <!-- 顶部按钮 -->
      <div style="margin-bottom: 16px; text-align: left">
        <!-- <el-button type="primary" size="mini">下载当前页电子回单</el-button> -->
        <el-button
          type="primary"
          size="mini"
          :loading="flag.downloadInvoiceing"
          @click="billDown"
          >下载发票运单表格</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="flag.downloadInvoiceing"
          @click="downloadInvoice"
          >下载电子发票及清单
        </el-button>
      </div>
      <el-table
        :data="itemTableDetail"
        v-loading="flag.dialogLoading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        height="500px"
      >
        <el-table-column
          fixed="left"
          align="center"
          prop="index"
          label="序号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="WaybillID"
          label="运单号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceType"
          label="发票类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskType"
          label="业务类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskSceneName"
          label="场景类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ConsignName"
          label="货主单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseCreditCodeHZ"
          label="货主税号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddressHZ"
          label="货主地址"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseContactsPhoneHZ"
          label="货主电话"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="BankerNameHZ"
          label="货主开户行"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="BankerNumberHZ"
          label="货主账号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskDatetime"
          label="申请开票时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LoadingDatetime"
          label="运输起始时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="SignDatetime"
          label="运输结束时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsUnit"
          label="货品单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverAllotCount"
          label="货品数量"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverCarryPrice"
          label="运输单价"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.DriverCarryPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarriageTotalPrice"
          :label="getWaybillType.sceneId == 7 ? '工单费' : '运费'"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="费率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ServiceCharge"
          label="服务费/税费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ServiceCharge | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TotalAmount"
          label="含税金额"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="税率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.DefaultRate }}%</p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop=" NonTaxTotalAmount"
          label="不含税金额"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.NonTaxTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop=" TaxFee" label="税额" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TaxFee | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarNumber"
          label="工程设备名称"
          width="200"
          v-if="getWaybillType.sceneId == 7"
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openVehicleInfo(scope.row)"
            >
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarNumber"
          label="车牌号"
          width="200"
          v-if="getWaybillType.sceneId != 7"
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openVehicleInfo(scope.row)"
            >
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="VehicleType"
          label="车辆类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsName"
          label="货品名称"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="OriginAddress"
          label="起始地"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openMap(scope.row)"
            >
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ArriveAddress"
          label="到达地"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openMap(scope.row)"
            >
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverName"
          :label="getWaybillType.sceneId == 7 ? '操作员姓名' : '司机姓名'"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverIDCard"
          :label="getWaybillType.sceneId == 7 ? '操作员身份证' : '司机身份证'"
          width="200"
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openDerverInfo(scope.row.DriverUserID)"
            >
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseFullName"
          label="服务商单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseCreditCode"
          label="服务商税号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="服务商地址"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseContactsPhone"
          label="服务商电话"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="BankerName"
          label="服务商开户行"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="BankerNumber"
          label="服务商账号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        background
        class="invoicePagination"
        @current-change="handleInvoicePageChange"
        :current-page.sync="invoicePagination.page"
        :page-size="invoicePagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="invoicePagination.total"
      >
      </el-pagination>
      <!-- 发票列表 -->
      <div
        style="margin: 16px 0; display: flex; flex-wrap: nowrap"
        v-if="!$entrustSceneIds.includes(Number(getWaybillType.sceneId))"
      >
        <div v-for="item in dPinvoiceImgList" :key="item">
          <el-image
            style="width: 100px"
            v-if="item"
            :src="item"
            :preview-src-list="dPinvoiceImgList"
          >
          </el-image>
          <span style="color: red; margin: 5px 5px 0px 0px" v-else
            >已由系统回填信息</span
          >
        </div>
      </div>
      <div
        class="invoice"
        v-if="
          dPinvoiceImgList.length != 0 &&
          $entrustSceneIds.includes(Number(getWaybillType.sceneId))
        "
        style="display: flex; align-items: center; flex-wrap: wrap"
      >
        <div v-for="(item, index) in dPinvoiceImgList" :key="index">
          <el-image
            class="img"
            :src="item"
            :preview-src-list="dPinvoiceImgList"
            style="width: 200px; height: 100px; margin-bottom: 10px"
            v-if="item"
          >
            <div
              slot="error"
              class="image-slot"
              style="display: flex; justify-content: center; margin-top: 30px"
            >
              <i class="el-icon-picture-outline" style="font-size: 50px"></i>
            </div>
          </el-image>
          <span style="color: red; margin: 5px 5px 0px 0px" v-else
            >已由系统回填信息</span
          >
          <el-tooltip :content="item" v-if="item">
            <p
              @click="downloadVoucher(item)"
              style="
                width: 180px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: blue;
              "
            >
              {{ item }}
            </p>
          </el-tooltip>
        </div>
      </div>
      <!-- 地图dialog -->
      <el-dialog
        width="800px"
        title="查看地图"
        :visible.sync="flag.showMap"
        append-to-body
      >
        <!-- 地图组件 -->
        <TXmap
          style="height: 600px"
          v-if="flag.showMap"
          :list="mapList"
          ref="map"
        ></TXmap>
      </el-dialog>
      <!-- 车辆信息dialog -->
      <el-dialog
        width="1300px"
        title="车辆信息"
        :visible.sync="flag.showVehicleInfo"
        append-to-body
      >
        <VehicleInfo
          ref="vehicleInfo"
          v-if="flag.showVehicleInfo"
          :vehicleInfo="vehicleInfo"
        ></VehicleInfo>
      </el-dialog>
      <!-- 司机信息组件 -->
      <el-dialog
        width="1300px"
        title="操作员信息"
        :visible.sync="flag.showDriverInfo"
        append-to-body
      >
        <DriverInfo :driverInfo="driverInfo" />
      </el-dialog>
    </el-dialog>
    <!-- 是否开票完成提示 -->
    <el-dialog
      class="invoiceFinish"
      :visible.sync="flag.showHint"
      width="500px"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      center
    >
      <p style="display: flex; align-items: center; justify-content: center">
        开票即将完成，请耐心等待<i
          class="el-icon-warning"
          style="font-size: 24px; color: #e6a23c"
        />
      </p>
      <div style="text-align: center">
        <el-button type="primary" size="mini" @click="goBack"
          >耐心等待</el-button
        >
      </div>
    </el-dialog>
    <!-- 显示服务商寄过来的发票种类 -->
    <el-dialog
      class="invoiceStatus"
      :visible.sync="flag.showInvoiceStatus"
      width="700px"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      center
    >
      <div
        slot="title"
        style="padding-bottom: 10px; border-bottom: 1px solid #f0f0f0"
      >
        服务商已完成开票
      </div>
      <p v-if="invoiceStatus == 2">发票电子文件可在运单详情中查看</p>
      <div v-if="invoiceStatus == 1">
        <p>纸质发票由服务商负责向您寄送：</p>
        <p>
          物流公司：<span style="#2081FF">{{
            invoiceInfo.ExpressCompany
          }}</span>
        </p>
        <p>
          物流单号：<span style="#2081FF">{{ invoiceInfo.ExpressNumber }}</span>
        </p>
      </div>
      <div
        slot="footer"
        style="
          text-align: center;
          border-top: 1px solid #f0f0f0;
          padding-top: 10px;
        "
      >
        <el-button
          type="primary"
          size="mini"
          @click="flag.showInvoiceStatus = false"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 线下支付 -->
    <el-dialog
      class="invoiceFinish"
      :visible.sync="flag.confirmReceiptedStatus1"
      width="700px"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      center
    >
      <el-alert
        style="margin-bottom: 10px"
        title="温馨提示"
        type="warning"
        description="本结算单是“线下支付”请务必于7日内将支付凭证上传至结算信息页面的结算单中"
        :closable="false"
      >
      </el-alert>
      <div style="display: flex; align-items: center; justify-content: center">
        <i class="el-icon-success" style="color: #67c23a; font-size: 26px"></i>
        <span>操作完成</span>
      </div>
      <p>你的结算单“顺利完成”！祝你生意兴隆</p>
      <p>5秒后自动回到首页</p>
    </el-dialog>
    <!-- 线上支付 -->
    <el-dialog
      class="invoiceFinish"
      :visible.sync="flag.confirmReceiptedStatus2"
      width="700px"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      center
    >
      <el-alert
        style="margin-bottom: 10px"
        title="温馨提示"
        type="warning"
        description="本结算单是“线上支付”你可以在资金信息、账户信息收支明细等页面获取银行单子回单！在税务信息页面获取电子发票"
        :closable="false"
      >
      </el-alert>
      <div style="display: flex; align-items: center; justify-content: center">
        <i class="el-icon-success" style="color: #67c23a; font-size: 26px"></i>
        <span>操作完成</span>
      </div>
      <p>你的结算单“顺利完成”！祝你生意兴隆</p>
      <p>5秒后自动回到首页</p>
    </el-dialog>
    <!-- 驳回意见dialog -->
    <el-dialog
      width="700px"
      title="驳回意见"
      :visible.sync="flag.rejectModal"
      append-to-body
    >
      <p style="margin-bottom: 16px" v-if="tableData.length != 0">
        驳回：{{ tableData[0].SupplierName }}开具的发票
      </p>
      <el-input
        type="textarea"
        maxlength="200"
        placeholder="请输入处理意见，限制200个汉字"
        v-model="rejectContent"
      ></el-input>
      <div style="display: flex; justify-content: center; margin-top: 16px">
        <el-button size="mini" @click="flag.rejectModal = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="flag.reject"
          size="mini"
          @click="sendBack"
          :disabled="
            flag.confirmReceiptedStatus1 ||
            flag.confirmReceiptedStatus2 ||
            flag.confirmReceipting
          "
          >发送
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  invoiceCount,
  confirmReceipt,
  billCompleted,
  printInvoiceTaskDetailList,
  invoiceUrl,
  getInvoiceStatus,
  invoiceImageList,
  billDown,
  sendBack,
  RefundWaybill,
} from "@/api/waybill/waybillManage/index";
import { mapGetters } from "vuex";
import { downloadContract } from "@/api/finance/account/index";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import {
  getMapPath,
  getCarInfo,
  getDriverDetails,
  carInfoByNum,
} from "@/api/common/common";
import TXmap from "@/components/commonCmpt/TXmap";
import Step from "@/components/businessCmpt/step";
export default {
  data() {
    return {
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      invoicePagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      flag: {
        showHint: false,
        showTableDetail: false, //显示发票详情
        showInvoiceStatus: false, //显示服务商所开的发票种类
        confirmReceipting: false, //是否正在确认收票中
        disallowance: 1, //0未被驳回 1已驳回过
        showMap: false, //显示地图
        showVehicleInfo: false, //显示车辆信息
        showDriverInfo: false, //显示司机信息
        downloadInvoiceing: false, //下载表格中
        downloadAllInvoiceing: false, //下载所有表格中
        dialogLoading: false, //dialog弹窗是否是loading状态
        confirmReceiptedStatus1: false, //纸质票
        confirmReceiptedStatus2: false, //电子票
        rejectModal: false, //驳回意见弹窗
      },
      tableData: [], //运单列表
      itemTableDetail: [], //dialog里面的table列表
      settlementInfo: {}, //结算合计
      consignInfo: {}, //结算合计
      invoiceStatus: "", //发票种类 1.纸质票 2.电子票
      payType: "", //1线下 2线上 3线上 4线下
      invoiceInfo: {
        //纸质票发票信息
        ExpressCompany: "", //物流公司
        ExpressNumber: "", //物流单号
      },
      dPinvoiceImgList: [], //每个打票单号所拥有的的发票图片列表
      printTaskID: "", //打票单号
      mapList: [], //轨迹列表
      vehicleInfo: {}, //车辆信息
      driverInfo: {}, //司机信息
      rejectContent: "", //驳回内容
    };
  },
  methods: {
    downloadVoucher(url) {
      window.open(url, "_blank");
    },
    goBack() {
      if (this.$route.query.activeCmpt) {
        this.$router.push(
          `/waybill/pendingManage/index?activeCmpt=${this.$route.query.activeCmpt}`
        );
      } else {
        this.$router.push(`/waybill/pendingManage/index`);
      }
    },
    //打开地图
    openMap(item) {
      this.flag.dialogLoading = true;
      let params = {
        wayBillId: item.WaybillID,
        searchWord: item.LicensePlate ? item.LicensePlate : item.CarNumber,
        stDate: item.LoadingDatetime,
        edDate: item.SignDatetime,
        coordType: 2,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.showMap = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //打开车辆信息
    openVehicleInfo(item) {
      this.flag.dialogLoading = true;
      let params = {
        CarNumber: item.LicensePlate ? item.LicensePlate : item.CarNumber,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.flag.showVehicleInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //查看司机信息
    openDerverInfo(UserID) {
      this.flag.dialogLoading = true;
      let data = {};
      getDriverDetails({ UserID: UserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.flag.showDriverInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    open_detail(item) {
      this.invoicePagination.page = 1;
      this._detail(item.PrintTaskID).then(() => {
        this.invoiceImageList();
        this.flag.showTableDetail = true;
      });
    },

    async _detail(id) {
      const loading = this.$loading({
        lock: true,
        text: "请求中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.printTaskID = id;
      let params = {
        InvoiceTaskID: this.printTaskID,
        pageIndex: this.invoicePagination.page,
        pageSize: this.invoicePagination.pagesize,
      };
      await printInvoiceTaskDetailList({ Json: JSON.stringify(params) })
        .then((res) => {
          this.itemTableDetail = res.invoices.map((item, index) => {
            item.index = index + 1;
            return item;
          });

          this.invoicePagination.total = +res.totalRowCount || 0;
        })
        .finally(() => {
          loading.close();
        });
    },
    // 发票详情分页
    handleInvoicePageChange(e) {
      this.invoicePagination.page = e;
      this.printTaskID && this._detail(this.printTaskID);
    },
    //打开驳回框
    openRejectModal() {
      this.flag.rejectModal = true;
    },
    //驳回
    sendBack() {
      this.flag.reject = true;
      let params = {
        TaskID: this.getInvoiceTaskID,
        Remark: this.rejectContent,
      };
      sendBack(params)
        .then((res) => {
          this.goBack();
        })
        .finally(() => {
          this.flag.reject = false;
        });
    },
    //下载全部电子发票及清单
    downloadAllInvoice() {
      this.flag.downloadAllInvoiceing = true;
      let params = {
        InvoiceTask: this.getInvoiceTaskID,
      };
      invoiceUrl(params)
        .then((res) => {
          window.location.href = res.receipt.pathName;
        })
        .finally(() => {
          this.flag.downloadAllInvoiceing = false;
        });
    },
    //下载电子发票
    downloadInvoice() {
      this.flag.downloadInvoiceing = true;
      let params = {
        InvoiceTask: this.printTaskID,
      };
      invoiceUrl(params)
        .then((res) => {
          window.location.href = res.receipt.pathName;
        })
        .finally(() => {
          this.flag.downloadInvoiceing = false;
        });
    },
    //下载电子合同
    downloadContract(item) {
      const params = {
        WaybillID: item.WaybillID,
      };
      downloadContract({ json: JSON.stringify(params) }).then((res) => {
        window.location.href = res.pathName;
      });
    },
    //下载表格运单
    billDown() {
      this.downloadInvoiceing = true;
      let params = {
        invoiceTaskId: this.getInvoiceTaskID,
      };
      RefundWaybill(params)
        .then((res) => {
          window.location.href = res.data;
        })
        .finally(() => {
          this.downloadInvoiceing = false;
        });
    },
    //根据打票单号查询电子发票
    invoiceImageList() {
      let params = {
        PrintInvoiceTask: this.printTaskID,
      };
      invoiceImageList({
        Json: JSON.stringify(params),
      }).then((res) => {
        this.dPinvoiceImgList = res.invoiceImage.map(
          (item) => item.InvoiceImgUrl
        );
      });
    },
    //分页回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.invoiceCount();
    },
    //货主查询服务商是否开票完成
    billCompleted() {
      let params = {
        InvoiceTaskID: this.getInvoiceTaskID,
      };
      billCompleted(params).then((res) => {
        if (
          res.BillCompleted.TaskStatus == 1 ||
          res.BillCompleted.TaskStatus == 7
        ) {
          this.flag.showHint = true;
        }
        if (res.BillCompleted.TaskStatus == 4) {
          //4同意已开票 1同意但未开票 7服务商暂存 2已邮寄
          this.payType = res.BillCompleted.PayModeID;
          //如果开了票查看开的是什么票
          this.getInvoiceStatus();
          //等获取到开票单号后再那开票单号去请求列表
          this.invoiceCount();
        }
        //获取此单的驳回状态
        this.flag.disallowance = res.BillCompleted.Disallowance;
      });
    },
    //货主查询服务商开的纸质票还是电子票
    getInvoiceStatus() {
      let params = {
        TaskID: this.getInvoiceTaskID,
      };
      getInvoiceStatus(params)
        .then((res) => {
          this.invoiceStatus = res.invoiceStatus.Status;
          if (this.invoiceStatus == 1) {
            //1.纸质票
            this.invoiceInfo.ExpressCompany = res.invoiceStatus.ExpressCompany; //物流公司
            this.invoiceInfo.ExpressNumber = res.invoiceStatus.ExpressNumber; //物流单号
          } else if (this.invoiceStatus == 2) {
            //2.电子票
          }
          this.flag.showInvoiceStatus = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //获取合计和运单列表
    invoiceCount() {
      let params = {
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize,
        InvoiceTaskID: this.getInvoiceTaskID,
      };
      invoiceCount({
        Json: JSON.stringify(params),
        TaskSceneTypeID: this.getWaybillType.sceneId,
      }).then((res) => {
        this.settlementInfo = res.wayBill;
        this.consignInfo = res.ConsignInfo;
        this.pagination.total = Number(res.totalRowCount);
        this.tableData = res.invoices.map((item, index) => {
          item.index = index + 1;
          return item;
        });
      });
    },
    //确认收票
    confirmReceipt() {
      if (this.tableData.length == 0) {
        this.$message.warning("服务商尚未上传发票");
        return;
      }
      if (
        this.flag.confirmReceiptedStatus1 ||
        this.flag.confirmReceiptedStatus2
      ) {
        this.$message.warning("已点击过确认收票，请勿重复点击");
        return;
      }
      this.flag.confirmReceipting = true;
      let params = {
        InvoiceTaskID: this.getInvoiceTaskID,
      };
      confirmReceipt(params)
        .then((res) => {
          if (this.payType == 1 || this.payType == 4) {
            //线下
            this.flag.confirmReceiptedStatus1 = true;
          } else if (this.payType == 2 || this.payType == 3) {
            //线上
            this.flag.confirmReceiptedStatus2 = true;
          }
          setTimeout(() => {
            this.flag.confirmReceiptedStatus1 = false;
            this.flag.confirmReceiptedStatus2 = false;
            this.$router.push("/home");
          }, 5000);
        })
        .finally(() => {
          this.flag.confirmReceipting = false;
        });
    },
  },
  created() {
    //获取服务商是否已经给货主开完票了
    this.billCompleted();
  },
  computed: {
    ...mapGetters(["getInvoiceTaskID", "getWaybillType"]),
  },
  watch: {
    "flag.showMap"(val) {
      if (!val) {
        this.mapList = [];
      }
    },
  },
  components: {
    TXmap,
    Step,
    VehicleInfo,
    DriverInfo,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../assets/style/variable.scss";

.facilityBox {
  .settleBox {
    margin-bottom: 10px;
    padding: 10px 16px;
    background-color: #f0f0f0;
    color: #666;
  }

  .sumArea {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    p {
      width: 150px;
      margin-right: 10px;
      font-size: 20px;
      font-weight: 700;
    }

    .total {
      flex: 1;
      background: $primary;
      display: flex;
      flex-wrap: wrap;
      color: white;
      padding: 10px;
      border-radius: 5px;

      .item {
        width: 20%;
      }
    }
  }

  .bodyArea {
    .title {
      color: #2081ff;
      position: relative;
      text-indent: 15px;
      font-size: 14px;
      border-bottom: 1px solid $borderGray;
      padding: 8px 0;
      margin-bottom: 20px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: $primary;
      }
    }

    .pagination {
      text-align: left;
      margin-top: 10px;
    }

    .funcs {
      text-align: center;
    }
  }

  .invoicePagination {
    margin: 10px 0;
  }

  .step {
    margin: 0 auto 16px;
  }

  .invoiceStatus {
    p {
      text-align: center;
      margin: 10px 0;
    }
  }

  .invoiceFinish {
    p {
      text-align: center;
      margin: 10px 0;
    }
  }
}
</style>
